export const initReloadFunnelState = () => {
    var historyTraversal =
        event.persisted || (typeof window.performance != 'undefined' && window.performance.navigation.type === 2);

    if (historyTraversal) {
        let wrapper = document.querySelector('.geofinder-wrapper');
        let popup = document.querySelector('[data-popup-location]');

        if (!wrapper || !popup) return;

        popup.classList.add('hidden');
        wrapper.classList.remove('disable-scroll');
        wrapper.removeAttribute('style');
        document.querySelector('[data-header]').classList.remove('popup-console');
        document.querySelector('[data-popup-success-block]').classList.remove('success');
        document.querySelector('[data-popup-location]').classList.remove('success');
        document.querySelector('[data-popup-phone-number]').value = '';
    }
};
