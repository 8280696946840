import { initAccordion } from './components/initAccordion';
import { initTabs } from './components/initTabs';
import { initScrollAnimation } from './components/initScrollAnimation';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initForms } from './components/initForms';
import { initScrollLocker } from './assets/initScrollLocker';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initPhoneMaskAndValidatePhone } from './components/initPhoneMaskAndValidatePhone';
import { initReloadFunnelState } from './components/initReloadFunnelState';
import { initMenus } from './components/initMenus';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

document.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initHashEmail();
    initLazyLoadFonts();
    initScrollLocker();
    initPhoneMaskAndValidatePhone();
    initAccordion();
    initTabs();
    initScrollAnimation();
    initScrollToAnchor();
    initReloadFunnelState();
    initMenus();
    initForms();
}
