export const initTabs = () => {
    // Vars
    const tabNav = document.querySelectorAll('[data-tab-name]');
    const tabContent = document.querySelectorAll('[data-tab-content]');

    // Listeners
    tabNav.forEach((item) => {
        item.addEventListener('click', selectTabNav);
    });

    // Handlers
    function selectTabNav() {
        tabNav.forEach((item) => {
            item.classList.remove('is-active');
        });
        this.classList.add('is-active');
        selectTabContent(this.getAttribute('data-tab-name'));
    }

    // Functions
    function selectTabContent(tabName) {
        tabContent.forEach((item) => {
            item.classList.contains(tabName) ? item.classList.add('is-active') : item.classList.remove('is-active');
        });
    }
};
